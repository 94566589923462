<template>
  <div @click="toggle" class="ml-steps__item">
    <step :completed="completed" :show="show" @close="toggle">
      <div class="ml-steps-card__title">{{$t("mylist.welcomeMessage")}}</div>
      <template v-slot:icon>
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M11.75 4.25H4.25C4.05109 4.25 3.86032 4.32902 3.71967 4.46967C3.57902 4.61032 3.5 4.80109 3.5 5C3.5 5.19891 3.57902 5.38968 3.71967 5.53033C3.86032 5.67098 4.05109 5.75 4.25 5.75H11.75C11.9489 5.75 12.1397 5.67098 12.2803 5.53033C12.421 5.38968 12.5 5.19891 12.5 5C12.5 4.80109 12.421 4.61032 12.2803 4.46967C12.1397 4.32902 11.9489 4.25 11.75 4.25ZM11.75 7.25H4.25C4.05109 7.25 3.86032 7.32902 3.71967 7.46967C3.57902 7.61032 3.5 7.80109 3.5 8C3.5 8.19891 3.57902 8.38968 3.71967 8.53033C3.86032 8.67098 4.05109 8.75 4.25 8.75H11.75C11.9489 8.75 12.1397 8.67098 12.2803 8.53033C12.421 8.38968 12.5 8.19891 12.5 8C12.5 7.80109 12.421 7.61032 12.2803 7.46967C12.1397 7.32902 11.9489 7.25 11.75 7.25ZM13.25 0.5H2.75C2.15326 0.5 1.58097 0.737053 1.15901 1.15901C0.737053 1.58097 0.5 2.15326 0.5 2.75V10.25C0.5 10.8467 0.737053 11.419 1.15901 11.841C1.58097 12.2629 2.15326 12.5 2.75 12.5H11.4425L14.2175 15.2825C14.2876 15.352 14.3707 15.407 14.4621 15.4443C14.5534 15.4817 14.6513 15.5006 14.75 15.5C14.8484 15.5025 14.946 15.482 15.035 15.44C15.172 15.3837 15.2892 15.2882 15.372 15.1654C15.4547 15.0426 15.4993 14.8981 15.5 14.75V2.75C15.5 2.15326 15.2629 1.58097 14.841 1.15901C14.419 0.737053 13.8467 0.5 13.25 0.5ZM14 12.9425L12.2825 11.2175C12.2124 11.148 12.1293 11.093 12.0379 11.0557C11.9466 11.0183 11.8487 10.9994 11.75 11H2.75C2.55109 11 2.36032 10.921 2.21967 10.7803C2.07902 10.6397 2 10.4489 2 10.25V2.75C2 2.55109 2.07902 2.36032 2.21967 2.21967C2.36032 2.07902 2.55109 2 2.75 2H13.25C13.4489 2 13.6397 2.07902 13.7803 2.21967C13.921 2.36032 14 2.55109 14 2.75V12.9425Z"
            fill="#424142"
          />
        </svg>
      </template>
      <template v-slot:modal>
        <div class="card-wizard">
          <div class="card-wizard__title">
            <h3 class="header-3">{{ $t("mylist.widtgetListMessage") }}</h3>
          </div>
          <form class="card-wizard__container">
            <textarea
              v-model="welcomeMessage"
              class="bb-form-textarea"
              type="text"
              maxlength="256"
              rows="4"
              name="welcomeMessage"
              id="welcomeMessage"
              required=""
              :placeholder="$t('mylist.widgetListMessagePlaceholder')"
            >
            </textarea>

            <div class="card-wizard__actions">
              <loading-button v-if="loading"></loading-button>
              <button
                v-else
                class="button button--primary button--block"
                @click.prevent="save"
                :disabled="welcomeMessage === ''"
              >
                {{ $t("generic.save") }}
              </button>
            </div>
          </form>
        </div>
      </template>
    </step>
    <alert v-if="showAlertLocal" @retry="retry" :key="key" />
  </div>
</template>

<script>
import Step from "./Step";
import LoadingButton from "../../../components/LoadingButton";
import { mapGetters } from "vuex";
import OwnerListAlert from "./OwnerListAlert";

export default {
  name: "WelcomeMessageStep",
  components: {
    Step,
    "loading-button": LoadingButton,
    alert: OwnerListAlert,
  },
  data() {
    return {
      show: false,
      welcomeMessage: "",
      loading: false,
    };
  },
  computed: {
    ...mapGetters({
      user: "user",
      showAlertKey: "showAlertKey",
      showAlert: "showAlert",
    }),
    completed() {
      return (
        this.user?.welcomeMessage !== "" && this.user?.welcomeMessage != null
      );
    },
    showAlertLocal() {
      return this.showAlertKey === this.key && this.showAlert;
    },
    key() {
      return "list_welcome_message_step";
    },
  },
  methods: {
    async retry() {
      this.$store.commit("setAlertRetrying", true);
      await this.save();
      this.$store.commit("setAlertRetrying", false);
    },
    toggle() {
      this.show = !this.show;
    },
    async save() {
      this.loading = true;
      try {
        await this.$store.dispatch('updateWelcomeMessage', this.welcomeMessage)
      } catch {
        this.loading = false;
        this.$alert.error(
          this.$t("mylist.widgetListMessageError"),
          true,
          this.key
        );
        this.show = false;
        return;
      }
      this.$store.commit("updateUserField", {
        field: "welcomeMessage",
        value: this.welcomeMessage,
      });
      this.loading = false;
      window.mixpanel.track("Onboarding", {
        "Step Name": "Step 3: Wellcome message",
        Skipped: false,
        "Last O Slide": false,
        "On Seq Com": false,
      });
      window.mixpanel.people.increment("Steps Taken");
      this.show = false;
      this.$alert.success(
        this.$t("mylist.widgetListMessageSuccess"),
        this.key
      );
    },
  },
  mounted() {
    this.welcomeMessage = this.user?.welcomeMessage;
  },
};
</script>
